var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.bodyPartsData),function(bodyPart,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":bodyPart.bodyPartNameCurrent,"description":bodyPart.fullCode,"imagePath":bodyPart.bodyPartImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(bodyPart.isHaveChildrenStatus)?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'BodyParts',
          params: {
            parentBodyPartToken: bodyPart.bodyPartToken,
          },
        },"title":_vm.$t('BodyParts.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/open.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setBodyPartData(bodyPart);
          _vm.openBottomSheet('BodyPartInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setBodyPartData(bodyPart);
          _vm.openBottomSheet('BodyPartQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasBodyPartEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setBodyPartData(bodyPart);
          _vm.openBottomSheet('BodyPartUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasBodyPartFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.BodyPartDelete",modifiers:{"BodyPartDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setBodyPartData(bodyPart)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasBodyPartChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.BodyPartChangeActivationType",modifiers:{"BodyPartChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setBodyPartData(bodyPart)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setBodyPartData(bodyPart);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }